import { createContext, useContext, useEffect, useState } from "react";
import { getUser } from "src/api";

export const UserContext = createContext();

export function UserWrapper({ children }) {
  const [currentUser, setCurrentUser] = useState({});
  const [loading, setLoading] = useState(true);
  const [tokenExpiresAt, setTokenExpiresAt] = useState(null);
  // Auth persistance
  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem("ph_token");
        if (!token) {
          setCurrentUser({});
          setTokenExpiresAt(null);
          return;
        }
        // setCurrentUser(JSON.parse(localStorage.getItem("userInfo")));
        const { data, status } = await getUser();
        if (status === 401) {
          localStorage.removeItem("ph_token");
          setCurrentUser({});
          setTokenExpiresAt(null);
          return;
        }
        setCurrentUser(data?.data);
        setTokenExpiresAt(data?.tokenExpiresAt);
      } catch (error) {
        console.error(error);
        setCurrentUser({});
        setTokenExpiresAt(null);
        localStorage.removeItem("ph_token");
      } finally {
        setLoading(false);
      }
    })();
  }, []);
  const sharedState = {
    loading,
    currentUser,
    setCurrentUser,
    tokenExpiresAt,
    setTokenExpiresAt,
  };
  return (
    <UserContext.Provider value={sharedState}>{children}</UserContext.Provider>
  );
}

export function useUser() {
  return useContext(UserContext);
}
